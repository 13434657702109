import React from 'react';
import Seo from '../components/Seo/Seo'
import {graphql} from 'gatsby';
import { v4 as uuidv4 } from 'uuid';
import {Layout} from '../components/Layout';
import { OfferPastile } from '../components';

export default function Offer({data}) {
  const { nodes } = data.allMdx;
  return <Layout>
    <Seo title="Oferta" />
    <div className="flex flex-col items-center py-6">
      {
        nodes.map(node => <OfferPastile key={uuidv4()} offer={node.frontmatter} />)
      }
    </div>
  </Layout>
}


export const offerQuery = graphql`
query offer {
  allMdx {
    nodes {
      frontmatter {
        content
        title
        slug
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}
`